import { graphql, Link } from "gatsby"
import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"

import Layout from "components/layout"
import SEO from "components/seo"
import { Hero } from "../components/Hero"
import { Container, Section } from "../components/Layouts"
import { Text, Image } from "../components/Core"
import Slider from "react-slick"
import MobileCarousel from "../components/Carousels/MobileCarousel"

function About(props) {
  let teamSliderRef = useRef(null)
  const post = props.data.allUniquePagesJson.nodes[0]
  const {
    ourPurpose,
    team,
    jobs,
    heroSection: { heading, blurb, desktopImage, mobileImage }
  } = post

  const teamSliderSettings = {
    className: "center",
    centerMode: false,
    centerPadding: "0",
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          centerPadding: "20px",
          infinite: false
        }
      }
    ]
  }

  const jobSliderSettings = {
    className: "center",
    centerMode: true,
    speed: 500,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    centerPadding: "20px"
  }

  return (
    <Layout navSpacer className="abt" path={props.path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <Hero
        noOverlapMobile
        heading={heading}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        blurb={blurb}
      />

      <Section>
        <Container>
          <div className="abt__purpose">
            <div className="rounded bg-secondary">
              <Text className="color-white" as="h2" text={ourPurpose.heading} />
            </div>
            <div className="rounded bg-accent">
              <Text text={ourPurpose.body} />
              <Image
                className="is-hidden-desktop"
                useAR={false}
                publicId={ourPurpose.desktopImage}
              />
            </div>
            <div className="rounded bg-accent image-only is-hidden-touch">
              <Image
                useAR={false}
                width="400"
                height="580"
                publicId={ourPurpose.desktopImage}
              />
            </div>
          </div>
        </Container>
      </Section>

      {team.hasThisSection && (
        <Section>
          <Container className="abt__team">
            <div>
              <Text
                className="has-text-centered color-blue"
                as="h2"
                text={team.subheading}
              />
              <Text className="has-text-centered" as="h3" text={team.heading} />
            </div>

            <div className="abt__team-carousel slick-card-scale is-hidden-touch">
              <button
                aria-label="previous carousel slide"
                className="reset team-arrow"
                onClick={() => teamSliderRef.slickPrev()}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <Slider
                ref={slider => {
                  teamSliderRef = slider
                }}
                {...teamSliderSettings}>
                {team.members.map((member, i) => (
                  <div key={i} className="abt__single-member">
                    <Image publicId={member.image} />
                    <Text as="h4" text={member.name} />
                    <Text as="h5" text={member.role} />
                  </div>
                ))}
              </Slider>
              <button
                aria-label="next carousel slide"
                className="reset team-arrow right"
                onClick={() => teamSliderRef.slickNext()}>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
            <div className="is-hidden-desktop">
              <MobileCarousel>
                {team.members.map((member, i) => (
                  <div key={i} className="abt__single-member">
                    <Image publicId={member.image} />
                    <Text as="h4" text={member.name} />
                    <Text as="h5" text={member.role} />
                  </div>
                ))}
              </MobileCarousel>
            </div>
          </Container>
        </Section>
      )}
      {jobs.hasThisSection && (
        <Section>
          <div className="abt__jobs">
            <Container>
              <Text className="color-blue" as="h3" text={jobs.heading} />
            </Container>
            <Container medium>
              <div className="abt__jobs-list">
                {jobs.openings.map((job, i) => (
                  <div key={i} className="abt__single-job">
                    {job.url ? (
                      <Link
                        to={job.url}
                        title={`Learn more about the ${job.title} position`}>
                        <Text as="h4" text={job.title} />
                        <Text as="p" text={job.description} />
                        <div className="abt__job-btn">
                          <FontAwesomeIcon icon={faArrowUpRight} />
                        </div>
                      </Link>
                    ) : (
                      <>
                        <Text as="h4" text={job.title} />
                        <Text as="p" text={job.description} />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </Container>

            <div className="abt__jobs-carousel slick-card-scale">
              <Slider {...jobSliderSettings}>
                {jobs.openings.map((job, i) => (
                  <div class="abt--kd" key={i}>
                    <div className="abt__single-job slick-card">
                      <Link
                        to={job.url}
                        title={`Learn more about the ${job.title} position`}>
                        <Text as="h4" text={job.title} />
                        <Text as="p" text={job.description} />
                        <div className="abt__job-btn">
                          <FontAwesomeIcon icon={faArrowUpRight} />
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query aboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          heading
          blurb
          desktopImage
          mobileImage
        }
        ourPurpose {
          hasThisSection
          heading
          body
          desktopImage
          mobileImage
        }
        team {
          hasThisSection
          subheading
          heading
          members {
            name
            role
            image
          }
        }
        jobs {
          hasThisSection
          heading
          openings {
            title
            description
            url
          }
        }
      }
    }
  }
`

export default About
